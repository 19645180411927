import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import {
    Container,
    Row,
    Col,
    Card
} from 'reactstrap';
import styles from './styles.module.scss';
import Link from "next/link"
import React, {
    useRef,
    useEffect,
    useState
} from "react";
import Arrow from "/public/site-assets/svg/black-arrow.svg"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper"
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Caret from '../../public/site-assets/svg/caret.svg';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import md5 from "md5";
import imageMetaData from "../../.sourceflow/image_metadata.json";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

export default function ImageWithVideoBanner({
    global,
    pathPrefix,
    customClass = "",
    content,
    buttonTitle,
    buttonLink,
    imageURL,
    imageALT,
    videoLink,
    videoGalleryClass,
    sectionId,
    pageAnchor
}) {
    Fancybox.bind("[data-fancybox]", {
        // Your custom options
    });
    return (
        <section className={`${styles.root} ${customClass}`} id={`${sectionId}`}>
            <Container>
                <div className="position-relative p-md-5 p-4 rounded-3 overflow-hidden">
                    <div className="imageWrapper position-absolute">
                        <SourceFlowImage
                            src={imageURL}
                            imagesMetaData={imageMetaData}
                            path={`${pathPrefix ? pathPrefix : md5(imageURL)}.image`}
                            size="1000x"
                            alt={imageALT}
                            className="card-img rounded-0"
                        />
                    </div>
                    <div className="position-relative">
                        <div className="contentWrapper">
                            <SourceFlowText global={global} path={`${pathPrefix}.content`} type="html">
                                {content}
                            </SourceFlowText>
                            {videoLink ?
                                <a
                                    className="fancyBox d-inline-block position-absolute"
                                    data-fancybox={`${videoGalleryClass}`}
                                    href={videoLink}
                                    aria-label="Play"
                                >
                                    <span className="play">
                                        <Caret />
                                    </span></a> : null}
                        </div>
                        {buttonLink ?
                            <div>
                                {pageAnchor ?
                                    <AnchorLink offset='80' href={`#${pageAnchor}`} className="primaryBtn no-arrow m-0">
                                        {buttonTitle}
                                    </AnchorLink> :
                                    <Link href={`${buttonLink}`}>
                                        <a className="primaryBtn no-arrow m-0">
                                            {buttonTitle}
                                        </a>
                                    </Link>}
                            </div> : null}
                    </div>
                </div>
            </Container>
        </section>
    )
}